/* jshint ignore:start */

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {


        // JavaScript to be fired on all pages
        if (typeof (isSchema) !== 'undefined'){


            jQuery(document).ready(function() {

            var modalStatus = false;

            const modalContainer = document.getElementById('modal_container');
            const modalBox = document.getElementById('modal_box');
            const modalCross = document.getElementById('modal_cross');

            var modalTitle = document.getElementById('modal_title');
            var modalContent = document.getElementById('modal_content');

            // Elements cliquables du SVG
            const clickableElts = [
              document.getElementById("solution-editique"),
              document.getElementById("efluid-net"),
              document.getElementById("agence-en-ligne"),
              document.getElementById("efluid"),
              document.getElementById("solutions-mobiles"),
              document.getElementById("applications-metiers"),
              document.getElementById("decisionnel"),
              document.getElementById("agence-en-ligne-clients"),
              document.getElementById("aps")
            ];



            const popup_solution_editique = document.querySelector('#solution-editique');
            const popup_efluidnet = document.querySelector('#efluid-net');
            const popup_agence_en_ligne = document.querySelector('#agence-en-ligne');
            const popup_solutions_mobiles = document.querySelector('#solutions-mobiles');
            const popup_efluid = document.querySelector('#efluid');
            const popup_applications_metiers = document.querySelector('#applications-metiers');
            const popup_decisionnel = document.querySelector('#decisionnel');
            const popup_agence_en_ligne_clients = document.querySelector('#agence-en-ligne-clients');
            const popup_aps = document.querySelector('#aps');



            // Données des éléments cliquables
            const modalData = {
              'solution-editique': {
                'color': window.getComputedStyle(document.getElementById('solution-editique').getElementsByTagName('path')[0]).getPropertyValue('fill'),
                'title': popup_solution_editique.getAttribute('data-popuptitle_solution_editique'),
                'content': popup_solution_editique.getAttribute('data-popuptext_solution_editique')
              },
              'efluid-net': {
                'color': window.getComputedStyle(document.getElementById('efluid-net').getElementsByTagName('path')[0]).getPropertyValue('fill'),
                'title': popup_efluidnet.getAttribute('data-popuptitle_efluidnet'),
                'content': popup_efluidnet.getAttribute('data-popuptext_efluidnet')
              },
              'agence-en-ligne': {
                'color': window.getComputedStyle(document.getElementById('agence-en-ligne').getElementsByTagName('path')[0]).getPropertyValue('fill'),
                'title': popup_agence_en_ligne.getAttribute('data-popuptitle_agence_en_ligne'),
                'content': popup_agence_en_ligne.getAttribute('data-popuptext_agence_en_ligne')
              },
              'efluid': {
                'color': window.getComputedStyle(document.getElementById('efluid').getElementsByTagName('path')[0]).getPropertyValue('fill'),
                'title': popup_efluid.getAttribute('data-popuptitle_efluid'),
                'content': popup_efluid.getAttribute('data-popuptext_efluid')
              },
              'solutions-mobiles': {
                'color': window.getComputedStyle(document.getElementById('solutions-mobiles').getElementsByTagName('path')[0]).getPropertyValue('fill'),
                'title': popup_solutions_mobiles.getAttribute('data-popuptitle_solutions_mobiles'),
                'content': popup_solutions_mobiles.getAttribute('data-popuptext_solutions_mobiles')
              },
              'applications-metiers': {
                'color': window.getComputedStyle(document.getElementById('applications-metiers').getElementsByTagName('path')[0]).getPropertyValue('fill'),
                'title': popup_applications_metiers.getAttribute('data-popuptitle_applications_metiers'),
                'content': popup_applications_metiers.getAttribute('data-popuptext_applications_metiers')
              },
              'decisionnel': {
                'color': window.getComputedStyle(document.getElementById('decisionnel').getElementsByTagName('path')[0]).getPropertyValue('fill'),
                'title': popup_decisionnel.getAttribute('data-popuptitle_decisionnel'),
                'content': popup_decisionnel.getAttribute('data-popuptext_decisionnel')
              },
              'agence-en-ligne-clients': {
                'color': window.getComputedStyle(document.getElementById('agence-en-ligne-clients').getElementsByTagName('path')[0]).getPropertyValue('fill'),
                'title': popup_agence_en_ligne_clients.getAttribute('data-popuptitle_agence_en_ligne_clients'),
                'content': popup_agence_en_ligne_clients.getAttribute('data-popuptext_agence_en_ligne_clients')
              },
              'aps': {
                'color': window.getComputedStyle(document.getElementById('aps').getElementsByTagName('path')[0]).getPropertyValue('fill'),
                'title': popup_aps.getAttribute('data-popuptitle_aps'),
                'content': popup_aps.getAttribute('data-popuptext_aps')
              },
            };

            // Fonction exécutée au click sur un élément

            var clickOnButton = function(i) {
              clickableElts[i].addEventListener('click', function () {
                // Si la modale est ouverte
                if (modalStatus) {
                  closeModal();
                }
                // Si la modale est fermée
                else {
                  modalSelected = modalData[clickableElts[i].getAttribute('id')];
                  openModal(modalSelected['color'], modalSelected['title'], modalSelected['content']);
                }
              });

            }

            // Fonction d'ouverture de modale
            var openModal = function (color, title, content) {
              if (color && title && content) {
                document.getElementsByTagName('body')[0].style.overflow = 'hidden';
                modalStatus = true;
                modalContainer.classList.add('modal_active');
                modalContainer.classList.remove('modal_desactive');
                modalContainer.style.display = 'flex';
                modalContainer.style.overflowY = 'scroll';
                modalTitle.style.color = color;
                modalTitle.textContent = title;
                modalContent.innerHTML = content;
              }
            }

            // Fonction de fermeture de modale
            var closeModal = function () {
              document.getElementsByTagName('body')[0].style.overflow = '';
              modalStatus = false;
              modalContainer.classList.remove('modal_active');
              modalContainer.classList.add('modal_desactive');
              setTimeout(function () {
                modalContainer.style.display = '';
              }, 400);
            }

            // Appel de la fonction d'ajout d'écouteur d'événements sur chaque élément cliquable
            for (var i = 0; i < clickableElts.length; i++) {
              clickOnButton(i);
            }

            // Ecouteur d'événement pour fermer la modale au click en dehors
            modalContainer.addEventListener('click', function (e) {
              if (e.target.getAttribute('id') === 'modal_container' && modalStatus) {
                closeModal();
              }
            });

            // Ecouteur d'événement pour fermer la modale au click sur la croix
            modalCross.addEventListener('click', function () {
              if (modalStatus) {
                closeModal();
              }
            });

            // Ecouteur d'événement pour fermer la modale lors de l'appui sur échap
            window.addEventListener('keydown', function (e) {
              if (e.keyCode === 27) {
                closeModal();
              }
            });
          });
        }

        $('.lang-wrapper').click(function () {
          $(this).toggleClass('show');
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        $(".main_popup--block--cross").click(function(){
          $(".main_popup").fadeOut();
        });

        $(document).mouseup(function (e){
          var container = $(".main_popup--block");
          var main = $(".main_popup");
          if (!container.is(e.target) && container.has(e.target).length === 0){
            main.fadeOut();
          }

        });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
