(function($) {

	/**********************************************************
	|	File input text
	**********************************************************/
	$('#customFile-autre-document, #customFile-cv').on('change',function(){
		console.log($(this).val());
		console.log($(this).closest('.custom-file').find('.custom-file-label'));
        //get the file name
        var fileName = $(this).val();
        //replace the "Choose a file" label
        $(this).closest('.custom-file').find('.custom-file-label').html(fileName);
    });

	/**********************************************************
	|	Function header fixed padding container
	**********************************************************/
	$(document).ready( function(){
			var header 					= jQuery('header#header');
			var container_padding 		= jQuery('.bandeau-home, .bandeau');

    jQuery(container_padding).css({ 'padding-top': jQuery(header).outerHeight() });

    // Popup iframe
    $('a.js-popup-iframe').each(function () {
        $this = $(this);
        var href = $this.data("src");

        $this.colorbox({
          href: href,
          'iframe': true,
          innerWidth: '80%',
          innerHeight: '70%',
        });
      });

	});

	/**********************************************************
	|	Scrolling bloc parametrage
	**********************************************************/
	$(document).ready(function() {
		$('.bloc-parametrage .nav a').click( function() { // Au clic sur un élément
			var ancre = $(this).attr('href'); // Page cible

			if($('.tab-content .tab-pane').hasClass('active')){

			}else{
				setTimeout(function(){
					console.log($(ancre).offset().top);
					var speed = 750; // Durée de l'animation (en ms)
					$('html, body').animate({
					    scrollTop: ($(ancre).offset().top - 300)
					},speed);
				}, 250);
			}
		});
	});



	/**********************************************************
	|	Document ready
	**********************************************************/
	$(document).ready( function(){


		/**********************************************************
		| Template architecture evolutive | click open detail schema
		**********************************************************/
		$('.bloc-architecture__schema').click( function(){

			var cible 		= $(this).data('target');
			var container 	= $(this).closest('.bloc-architecture__schema');

			if($(container).hasClass('active')){
				$('.bloc-architecture__schema__description[data-value="'+cible+'"]').removeClass('active');
				$(this).removeClass('active');
			}else{

				// Remove all active class
				$('.bloc-architecture .active').removeClass('active');
				// Add active class to target
				$('.bloc-architecture__schema__description[data-value="'+cible+'"]').addClass('active');
				// Add active selector
				$(this).addClass('active');
			}
		});

		/**********************************************************
		| Template cheque energie | Agradissement bloc texte
		**********************************************************/
		$('.bloc-2__detail__readmore').click( function(e){
			e.preventDefault();

			var btn 	= $(this);
			var bloc 	= $(this).closest('.bloc-2__detail__text');


			if($(bloc).hasClass('open'))
			{
				$(bloc).removeClass('open');

				//Changement titre button ( wpml)
				if(params.langCode === 'en'){
					btn.text('See more');
				}else{
					btn.text('En voir plus');
				}

			}else{
				$(bloc).addClass('open');

				//Changement titre button ( wpml)
				if(params.langCode === 'en'){
					btn.text('Reduce');
				}else{
					btn.text('Réduire');
				}
			}

		});



		/**********************************************************
		| Bloc agrandissement
		**********************************************************/
		$('.liste-bloc .liste-bloc__detail .liste-bloc__detail__readmore').click( function(e){
			e.preventDefault();

			var btn 	= $(this);
			var bloc 	= $(this).closest('.liste-bloc__detail');


			if($(bloc).hasClass('open'))
			{
				$(bloc).removeClass('open');

				//Changement titre button ( wpml)
				if(params.langCode === 'en'){
					btn.text('See more');
				}else{
					btn.text('En voir plus');
				}

			}else{
				$(bloc).addClass('open');

				//Changement titre button ( wpml)
				if(params.langCode === 'en'){
					btn.text('Reduce');
				}else{
					btn.text('Réduire');
				}
			}

		});


		/**********************************************************
		|	Fixed menu
		**********************************************************/
		var offset = $("#header").offset().top;

		$(document).scroll(function(){
		    var scrollTop = $(document).scrollTop();
		    if(scrollTop  > 1){
		        $("body").addClass("header--fixed");
		    }
		    else {
		        $("body").removeClass("header--fixed");
		    }
		});


		/**********************************************************
		|	Autoplay video modal
		**********************************************************/
		$(".js-modal-btn").modalVideo();

		/**********************************************************
		|	Height menu
		**********************************************************/
		//headerHeight();

	});

	/**********************************************************
	|	Height menu
	**********************************************************/
	$(window).resize( function(){
		headerHeight();
	});


})(jQuery); // Fully reference jQuery after this point.



/**********************************************************
| Toogle Menu
**********************************************************/
function menuToogle(){
	var menu = jQuery('body');
	//FERMETURE
	if( jQuery(menu).hasClass('menu-responsive-open') ){
		jQuery('button.hamburger').removeClass('is-active');
		jQuery(menu).removeClass('menu-responsive-open');
	}else{
	//OUVERTURE
	jQuery('button.hamburger').addClass('is-active');
		jQuery(menu).addClass('menu-responsive-open');
	}
}
