(function($) {

	
	/**********************************************************
	| Function More client	
	**********************************************************/  
	$('.page-template-template-nos-clients-php').on('click', '#ajax__more-client', function(e){
		e.preventDefault();
		
		// Button ajax
		button 					= $(this);
		button_text_initial 	= $(button).text();
		//console.log(button_text_initial);	
			
		
		// Get data for script php
	    data = {
			'action': 'loadmore_client',
			'id': button.attr('data-id'),
			'page': button.attr('data-page'),
			'per-page': button.attr('data-per-page'),
		};
		
		// request Ajax
		$.ajax({
			url : ajax_params.ajaxurl, // AJAX handler
			data : data,
			type : 'POST',
			beforeSend : function ( xhr ) {
				button.html('<span>...</span>'); // change the button text, you can also add a preloader image
			},
			success : function( data ){
				//console.log(data);
				if( data ) { 
					
					new_current_page = parseInt(button.attr('data-page')) + 1;
					button.attr('data-page', new_current_page);
					button.html('<span>'+button_text_initial+'</span>'); // Change texte button to original value
					
					$('.liste-client .row').append(data); // Add data to liste-actu > row 
					
					
					if($('.liste-client .row').find('.stop-list-reference').length){
						console.log('reove button');
						button.remove();	
					}

					
				} else {
					button.remove(); // if no data, remove the button as well
				}
			}
		});
	});
	

})(jQuery); // Fully reference jQuery after this point.
